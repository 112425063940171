.sidebar {
  padding: 24px 16px;
  width: 216px;
  position: sticky;
  top: 1px;

  .sidebar-item {
    a {
      display: inline-flex;
      padding: 8px 16px;
      color: var(--gray-600);
      text-decoration: none;
      border-radius: 8px;
      &:hover {
        background-color: var(--gray-50);
      }
      &:active {
        background-color: var(--gray-100);
      }
      &.active {
        color: var(--gray-950);
        font-weight: 700;
      }
    }
  }
}
