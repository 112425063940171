@import './styles/index.scss';
@import '~@just-ai/just-ui/scss/basic/jaicp/colors';
@import '~@just-ai/just-ui/scss/basic/build.main';
@import './styles/pseudoTailwind.scss';
@import './styles/mixins';

body {
  color: var(--gray-800);
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif;
}

.justui__icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.justui_button.btn {
  display: flex;
}

.bordered-panel-white {
  border: 1px solid rgba($color: white, $alpha: 0.2);
  &--rounded {
    border-radius: 0.375rem;
  }
}

.dark {
  label {
    color: white;
  }
}

.react-joyride {
  z-index: 1090;
  .react-joyride__step {
    z-index: 1091;
  }
}
.react-joyride__step {
  h4 {
    padding: 0 32px 0 16px;
    margin-bottom: 1rem !important;
  }
}

.hidden {
  display: none;
}

.mobileBottomModal {
  @include phone-only {
    top: 100vh !important;
    top: 100dvh !important;
    margin: 0 !important;
    transform: translateY(-100%) !important;

    .modal-base-buttons {
      display: flex;
      flex-direction: column;
      margin: 0 !important;
      flex-grow: 1;
      gap: 8px;
      > * {
        display: flex;
        width: 100%;
        margin: 0 !important;
        flex-grow: 1;
      }
    }
  }
}

.form-control,
.textAreaLikeElement,
.just-select-container input.single,
.phone-wrapper .phone-input {
  background-color: var(--grey-100);
}

.notification-container {
  @include phone-only {
    top: 100vh;
    top: 100dvh;
    transform: translateY(-100%);
    padding: 0;
    margin-top: -90px;
    width: 100dvw;

    .notification {
      max-width: 100dvw;
    }
  }
}

.overflow-scroll-y {
  overflow-y: scroll;
}
