@import './variables';

@mixin xs-up {
  @media (min-width: map-get($grid-breakpoints, 'xs')) {
    @content;
  }
}

@mixin sm-up {
  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    @content;
  }
}

@mixin md-up {
  @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    @content;
  }
}

@mixin lg-up {
  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    @content;
  }
}

@mixin xl-up {
  @media (min-width: map-get($grid-breakpoints, 'xl')) {
    @content;
  }
}

@mixin phone-only {
  @media (max-width: map-get($grid-breakpoints, 'sm')) {
    @content;
  }
}

@mixin notebook-only {
  @media (max-width: map-get($grid-breakpoints, 'lg')) {
    @content;
  }
}

@mixin scrollbar() {
  &::-webkit-scrollbar {
    min-width: 6px;
    width: 6px;
    padding-right: 2px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    min-height: 60px;
    height: 60px;
    border-radius: 100px;
    background-color: var(--palette-primary-30);
    opacity: 0.5;
    border: none;
  }

  &::-webkit-scrollbar-button {
    display: block;
    height: 0;
  }
}
