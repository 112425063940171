@import 'styles/mixins';

.FullContainer {
  display: flex;
  flex-direction: column;

  &__header {
    padding: 8px 24px;
    background: var(--gray-800);
    color: var(--white);
    width: 100%;

    .title {
      font-size: 32px;
    }
  }
}
