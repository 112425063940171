.WithSidebar {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;

  &__content {
    padding: 32px;
    width: 100%;
    display: grid;
  }
}
