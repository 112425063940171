.AppHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 24px;
  background: var(--gray-800);
  color: var(--white);
  width: 100%;
  box-shadow: 15px 0 var(--gray-800);

  .title {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 32px;
  }

  .documents {
    text-decoration: none;
    color: var(--white);

    &:hover {
      text-decoration: underline;
    }
  }

  .rightPanel {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}
