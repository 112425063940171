@import '~@just-ai/just-ui/scss/basic/custom/functions';
@import '~@just-ai/just-ui/scss/basic/custom/variables';

$primary: $indigo-600;
$primary-100: $indigo-100;
$primary-200: $indigo-200;
$primary-300: $indigo-300;
$primary-500: $indigo-500;
$primary-600: $indigo-600;
$primary-700: $indigo-700;
$primary-800: $indigo-800;

$paragraph-margin-bottom: 0px;

$theme-colors-states--buttons: map-merge(
  $theme-colors-states--buttons,
  (
    'primary': (
      'default': $primary,
      'hover': $primary-800,
      'active': $primary-800,
      'disabled': $grey-300,
      'focus': $primary-700,
      'flat-active-bg': $primary-200,
    ),
    'secondary': (
      'default': $secondary,
      'hover': $primary-800,
      'active': $primary-800,
      'disabled': $grey-300,
      'focus': $primary-700,
      'flat-active-bg': $primary-200,
    ),
  )
);

@function toRGB($color) {
  @return red($color), green($color), blue($color);
}

//colors counterparts #343541 - grey-800

:root {
  --white-20per: rgba(white, 0.2);
  --grey-800-rgb: #{toRGB($grey-800)};
  --above-inputs: 1000;
}
