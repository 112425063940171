//TODO посмотреть что из этого есть в БС

.relative {
  position: relative !important;
}
.z-index-auto {
  z-index: auto !important;
}
.absolute {
  position: absolute;
}
.top-unset {
  top: unset !important;
}
.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

.flex {
  display: flex;
}

.display-none {
  display: none;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-grow {
  flex-grow: 1;
}

.justify-start {
  justify-content: flex-start;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.h-screen {
  height: 100vh;
}

.w-screen {
  width: 100vw;
}

.flex-col {
  flex-direction: column;
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.text-white {
  color: white;
}

.text-black {
  color: var(--grey-950);
}

.text-center {
  text-align: center;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-sm {
  font-size: 12px;
}

.invisible-tw {
  visibility: hidden;
}

.group {
  &:hover {
    .group-hover-visible {
      visibility: visible;
    }
  }
}

.focus-visible {
  &:focus {
    visibility: visible;
  }
}

.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke;
}

@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.nowrap{
  white-space: nowrap;
}
